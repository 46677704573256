<template>
  <a-row>
    <a-col :gutter="24">
      <div :class="useCard ? 'card-full' : ''">
        <div class="detail">
          <template v-if="isCbt">
            <div class="cui__utils__heading mb-0 text-center">
              <h2>CARD QUESTION EDITOR</h2>
            </div>
            <div class="text-muted mb-3 text-center">
              You can edit Questions here
            </div>
            <hr />
            <div class="
                d-md-flex
                flex-column flex-lg-row flex-wrap
                align-items-center
                justify-content-lg-between
                text-center
              ">
              <div class="border-lg-right pr-lg-5">
                <a :disabled="!selectedSoal" @click.prevent="removeQuestion"
                  class="btn btn-outline-danger p-2 mb-3 mb-md-0 mr-md-3" style="min-width: 170px">
                  <a-icon type="minus-square" class="mr-2" />
                  <br />Remove Question
                </a>
                <a-popover v-model="visiblePop" title="Choose Question Type" trigger="click" placement="bottom">
                  <a-button id="cbt-add-multiple-question" @click.prevent="addQuestion('Multiple Choice')" size="large"
                    slot="content" type="default" block>
                    <a-icon id="cbt-add-multiple-question" type="unordered-list" />Multiple Choice
                  </a-button>
                  <a-button id="cbt-add-essay-question" @click.prevent="addQuestion('Essay')" class="mt-3" size="large"
                    slot="content" type="default" block>
                    <a-icon id="cbt-add-essay-question" type="align-left" />Essay
                  </a-button>
                  <a class="btn btn-outline-primary pt-2" style="min-width: 170px">
                    <a-icon id="cbt-soal-popover" type="plus-square" class="mr-2" />
                    <br />Create Question
                  </a>
                </a-popover>
              </div>
              <!-- <div>
                <a class="btn btn-outline-warning mt-3 mt-lg-0 pt-2" style="min-width:170px;">
                  <a-icon type="file-search" class="mr-2" />
                  <br />Preview as Student
                </a>
              </div> -->
              <div class="border-lg-left mt-3 mt-lg-0 pl-lg-5">
                <a @click.prevent="cancelEditing" class="btn btn-danger p-2 mb-3 mb-md-0 mr-md-3"
                  :class="loadingUpdateSoal ? 'disabled' : ''" style="min-width: 170px">
                  <a-icon type="close" class="mr-2" />
                  <br />Cancel Editing
                </a>
                <a @click.prevent="saveEditing" class="btn btn-primary p-2" :class="loadingUpdateSoal ? 'disabled' : ''"
                  style="min-width: 170px">
                  <a-icon type="save" class="mr-2" />
                  <br />
                  {{ loadingUpdateSoal ? "Updating..." : "Update Editing" }}
                </a>
              </div>
              <!-- <a-popover
                v-model="visiblePop"
                title="Choose Question Type"
                trigger="click"
                placement="bottom"
              >
                <a-button
                  @click.prevent="addQuestion('Multiple Choice')"
                  size="large"
                  slot="content"
                  type="default"
                >
                  <a-icon type="unordered-list" />Multiple Choice
                </a-button>
                <a-button
                  @click.prevent="addQuestion('Essay')"
                  class="mb-3"
                  size="large"
                  slot="content"
                  type="default"
                  block
                >
                  <a-icon type="align-left" />Essay
                </a-button>
                <a class="btn btn-outline-primary pt-2" style="min-width:170px;">
                  <a-icon type="plus-square" class="mr-2" />
                  <br />Create Question
                </a>
              </a-popover>-->
            </div>
            <hr />
            <CounterCbt :counterCBT="counterCBT" />
          </template>
          <template v-else>
            <div class="
                d-flex
                flex-wrap
                justify-content-center justify-content-md-between
                align-items-center
              ">
              <a :disabled="!selectedSoal" @click.prevent="removeQuestion"
                class="btn btn-outline-danger pt-2 mb-3 mb-md-0" style="min-width: 170px">
                <a-icon type="minus-square" class="mr-2" />
                <br />Remove Question
              </a>
              <a-popover v-model="visiblePop" title="Choose Question Type" trigger="click" placement="bottom">
                <a-button @click.prevent="addQuestion('3')" size="large" slot="content" type="default" block>
                  <a-icon type="unordered-list" />3 Choices
                </a-button>
                <a-button @click.prevent="addQuestion('4')" class="mt-3" size="large" slot="content" type="default" block>
                  <a-icon type="unordered-list" />4 Choices
                </a-button>
                <a-button @click.prevent="addQuestion('5')" class="mt-3" size="large" slot="content" type="default" block>
                  <a-icon type="unordered-list" />5 Choices
                </a-button>
                <a class="btn btn-outline-primary pt-2" style="min-width: 170px">
                  <a-icon type="plus-square" class="mr-2" />
                  <br />Create Question
                </a>
              </a-popover>
            </div>
          </template>
          <hr />
          <a-row :gutter="32">
            <a-col :sm="24" :lg="8" v-sticky="{ zIndex: 99, stickyTop: 10 }">
              <a-menu :selected-keys="selectedKey" mode="inline" :open-keys="openKeys"
                class="question-card shadow scroll-custom">
                <a-sub-menu key="soal">
                  <span slot="title">
                    <a-icon type="appstore" />
                    <span>LIST OF QUESTIONS</span>
                  </span>
                  <template v-if="questions.length">
                    <a-menu-item class="question-list" v-for="(item, index) in questions" :key="index"
                      @click="toSoal(index)">
                      {{ numberQuestion(index) }}.
                      <span v-html="clearTag(item.soal)"></span>
                    </a-menu-item>
                  </template>
                  <a-empty v-else style="margin-top: 150px" />
                </a-sub-menu>
              </a-menu>
            </a-col>
            <a-col :sm="24" :lg="16" class="mt-5 mt-lg-0">
              <template v-if="selectedSoal">
                <!-- {{currentSoal}} -->
                <template v-if="loadingSelectedSoal">
                  <a-skeleton active :paragraph="{ rows: 2 }" class="mt-4 mb-5" />
                  <a-skeleton active :paragraph="{ rows: 3 }" class="mt-5" />
                  <a-skeleton active :paragraph="{ rows: 5 }" class="mt-5" />
                </template>
                <template v-else>
                  <h3>
                    NO. {{ currentSoal.no }} ({{ currentSoal.tipe_soal }})
                  </h3>
                  <hr />
                  <div v-if="isCbt">
                    <div class="d-flex flex-column flex-lg-row flex-wrap">
                      <a-form-item label="Bobot Soal" class="mr-lg-2">
                        <a-input-number id="input-bobot-soal" size="large" :min="1" :max="100"
                          :value="currentSoal.bobot_soal" @change="handleBobot" :disabled="isAttendanceSubmitted"
                          style="width: 100%" />
                      </a-form-item>
                      <a-form-item label="Criteria" class="mr-lg-2">
                        <a-select size="large" :value="currentSoal.kriteria" style="width: 100%" @change="handleKriteria">
                          <a-select-option value="MD">MD</a-select-option>
                          <a-select-option value="SD">SD</a-select-option>
                          <a-select-option value="SK">SK</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item v-if="currentSoal.tipe_soal === 'Multiple Choice'" label="Correct Answer">
                        <a-select size="large" style="width: 100%" @change="handleKunciJawaban"
                          :value="currentSoal.kunci_jawaban" :disabled="isAttendanceSubmitted">
                          <a-select-option v-for="(_, i) in currentSoal.jawaban" :key="i"
                            :value="String.fromCharCode(65 + i).toLowerCase()">{{ String.fromCharCode(65 +
                              i).toLowerCase() }}</a-select-option>
                          <!-- <a-select-option value="b">b</a-select-option>
                          <a-select-option value="c">c</a-select-option>
                          <a-select-option value="d">d</a-select-option> -->
                        </a-select>
                      </a-form-item>
                    </div>
                    <div class="d-flex flex-column">
                      <a-form-item label="Standar Kompetensi" style="width: 100%">
                        <a-input v-model="currentSoal.standar_kompetensi" type="textarea" />
                      </a-form-item>
                      <a-form-item label="Indikator" style="width: 100%">
                        <a-input v-model="currentSoal.indikator" type="textarea" />
                      </a-form-item>
                    </div>
                  </div>
                  <a-form-item label="Question">
                    <!-- <div>
                      <MathTypeGeneric @updateValue="updateQuestion" />
                    </div> -->
                    <ckeditor v-model="currentSoal.soal" @input="onChange" :editor="editor" :config="editorConfig">
                    </ckeditor>
                  </a-form-item>
                  <template v-if="currentSoal.tipe_soal === 'Multiple Choice'">
                    <a-form-item v-if="isCbt" label="Choices">
                      <a-row class="answer" :gutter="[0, 32]" v-for="(itemJawaban, j) in currentSoal.jawaban" :key="j">
                        <a-col :sm="24" :md="2">
                          <a-button size="large" disabled
                            :type="String.fromCharCode(65 + j).toLowerCase() === currentSoal.kunci_jawaban ? 'primary' : 'default'">{{
                              String.fromCharCode(65
                                + j) }}</a-button>
                        </a-col>
                        <a-col :sm="24" :md="22">
                          <!-- <div>
                            <MathTypeGeneric @updateValue="(content) => updateAnswerEquation(content, j)" />
                          </div> -->
                          <ckeditor v-model="currentSoal.jawaban[j]" :editor="editor" :config="editorConfig"></ckeditor>
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <a-form-item v-else label="Choices">
                      <div class="py-2" v-for="(itemJawaban, j) in currentSoal.jawaban" :key="j">
                        <a-row :gutter="[0, 16]">
                          <a-col :sm="24" :lg="3" :xl="2">
                            <a-button @click.prevent="changeAnswer(choices[j])" :type="currentSoal.kunci_jawaban === choices[j]
                              ? 'primary'
                              : ''
                              " size="large">{{ choices[j] }}</a-button>
                          </a-col>
                          <a-col :sm="24" :lg="21" :xl="22">
                            <ckeditor @input="onChange" v-model="currentSoal.jawaban[j]" :editor="editor"
                              :config="editorConfig"></ckeditor>
                          </a-col>
                        </a-row>
                      </div>
                    </a-form-item>
                  </template>
                </template>
              </template>
              <template v-else>
                <div class="d-flex justify-content-center mt-5 h-100">
                  <div class="d-flex flex-column justify-content-center">
                    <div class="ml-md-5">
                      <img src="@/assets/illustration1.svg" class="img-fluid" alt />
                    </div>
                    <div class="text-center mt-4">
                      <h3>Don't be Confused</h3>
                      <p class="text-secondary mt-2">
                        You can do anything with your questions here
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import CounterCbt from '@/components/app/CbtGuru/CounterCbt'
import VueSticky from 'vue-sticky'
import { clearTag } from '@/helpers/regex.js'

// const MathTypeGeneric = () => import('@/components/app/MathTypeGeneric')
export default {
  props: ['listSoal', 'isAttendanceSubmitted', 'useCard', 'isCbt'],
  directives: {
    sticky: VueSticky,
  },
  components: {
    CounterCbt,
    // MathTypeGeneric,
  },
  data() {
    return {
      // current: ['appstore'],
      openKeys: ['soal'],
      selectedKey: ['1'],
      choices: ['A', 'B', 'C', 'D', 'E'],
      selectedSoal: false,
      visiblePop: false,
      loadingAddQuestion: false,
      loadingSelectedSoal: false,
      loadingUpdateSoal: false,
      questions: [],
      deletedQuestions: [],
      currentSoal: {
        key: '',
        id_soal: '',
        no: '',
        soal: '',
        bobot_soal: '',
        kunci_jawaban: '',
        tipe_soal: '',
        jawaban: ['', '', '', '', ''],
        kriteria: '',
        indikator: '',
        standar_kompetensi: '',
      },
      counterCBT: {
        counterQuestions: 0,
        counterEssay: 0,
        counterMultipleChoice: 0,
        counterEmpty: 0,
        counterBobot: 0,
      },
    }
  },
  methods: {
    updateAnswerEquation(content, index) {
      const data = [...this.currentSoal.jawaban]
      data[index] += content
      this.currentSoal.jawaban = data
    },
    updateQuestion(content) {
      this.currentSoal.soal += content
    },
    clearTag,
    onChange() {
      this.$emit('questions-change', this.questions)
    },
    changeAnswer(answer) {
      this.currentSoal.kunci_jawaban = answer
      this.$emit('questions-change', this.questions)
    },
    cancelEditing() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to cancel editing ? your changes will be lost</div>
        ),
        onOk: () => {
          this.$emit('cancel-edit')
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    saveEditing() {
      if (this.counterCBT.counterEmpty > 0) {
        this.editable = false
        this.$notification.error({
          message: 'Sorry.',
          description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
        })
      } else if (this.counterCBT.counterBobot !== 100) {
        this.editable = false
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to update & preview the kartu soal ?</div>
          ),
          onOk: () => {
            this.loadingUpdateSoal = true
            const { questions, deletedQuestions } = this
            this.$emit('save-edit', { questions, deletedQuestions })
          },
          onCancel: () => {
            this.loadingUpdateSoal = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    numberQuestion(index) {
      return ++index
    },
    toSoal(index) {
      this.selectedSoal = true
      this.loadingSelectedSoal = true
      this.selectedKey = [index]
      const soal = [...this.questions]
      const target = soal.filter((el, rowIndex) => index === rowIndex)[0]
      if (target) {
        this.currentSoal = target
        this.currentSoal.key = index
        this.currentSoal.no = this.numberQuestion(index)
      }
      // console.log(this.currentSoal)
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      delay(50).then(() => {
        this.loadingSelectedSoal = false
      })
    },
    handleBobot(value) {
      this.currentSoal.bobot_soal = value
      this.counterCBT.counterBobot = 0
      this.questions.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
      })
    },
    handleKriteria(value) {
      this.currentSoal.kriteria = value
    },
    handleKunciJawaban(value) {
      this.currentSoal.kunci_jawaban = value
    },
    addQuestion(type) {
      const data = { jawaban: [] }
      this.visiblePop = false
      if (this.isCbt) {
        if (this.isAttendanceSubmitted) {
          this.$notification.error({
            message: 'Error',
            description:
              'You cannot add the question, when students has already answered.',
          })
        } else {
          this.$confirm({
            title: 'Warning',
            content: (
              <div>Are you sure to add {type} question ?</div>
            ),
            onOk: () => {
              if (type === 'Multiple Choice') {
                const multipleChoiceAmount = this.institusi?.jumlah_pilihan_ganda || 4
                for (let i = 0; i < multipleChoiceAmount; i++) {
                  // data.jawaban.push(`${String.fromCharCode(65 + i)}. Type your choice here...`)
                  data.jawaban.push('Type your choice here...')
                }
                data.kunci_jawaban = 'a'
                this.counterCBT.counterMultipleChoice += 1
              } else {
                data.jawaban = ''
                this.counterCBT.counterEssay += 1
              }
              // data.id_cbt = idCbt
              data.tipe_soal = type
              data.soal = 'Type your question here...'
              data.standar_kompetensi = 'Type your standar kompetensi here...'
              data.indikator = 'Type your indikator here...'
              data.bobot_soal = 1
              data.kriteria = 'SD'
              this.counterCBT.counterQuestions += 1
              this.counterCBT.counterBobot += data.bobot_soal
              this.questions.push(data)
              const index = this.questions.length - 1
              this.toSoal(index)
              this.selectedKey = [index]
              this.$notification.success({
                message: 'Success',
                description:
                  'New Question has been added',
              })
            },
            onCancel: () => {
              this.visiblePop = false
            },
            centered: true,
            icon: 'warning',
            okType: 'primary',
            okText: 'Add',
          })
        }
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add {type} choices ?</div>
          ),
          onOk: () => {
            if (type === '3') {
              data.jawaban = ['Type your choice here...', 'Type your choice here...', 'Type your choice here...']
            } else if (type === '4') {
              data.jawaban = ['Type your choice here...', 'Type your choice here...', 'Type your choice here...', 'Type your choice here...']
            } else if (type === '5') {
              data.jawaban = ['Type your choice here...', 'Type your choice here...', 'Type your choice here...', 'Type your choice here...', 'Type your choice here...']
            }
            data.kunci_jawaban = 'A'
            // data.id_cbt = idCbt
            data.tipe_soal = 'Multiple Choice'
            data.soal = 'Type your question here...'
            this.counterCBT.counterQuestions += 1
            this.questions.push(data)
            const index = this.questions.length - 1
            this.toSoal(index)
            this.selectedKey = [index]
            this.$notification.success({
              message: 'Success',
              description:
                'New Question has been added',
            })
            this.$emit('questions-change', this.questions)
          },
          onCancel: () => {
            this.visiblePop = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      }
    },
    removeQuestion() {
      const soal = this.currentSoal
      // console.log(index)
      if (this.isAttendanceSubmitted) {
        this.$notification.error({
          message: 'Error',
          description:
            'You cannot delete this question, when students has already answered.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to remove this question ?</div>
          ),
          onOk: () => {
            this.counterCBT.counterQuestions -= 1
            this.counterCBT.counterBobot -= soal.bobot_soal
            if (soal.tipe_soal === 'Multiple Choice') {
              this.counterCBT.counterMultipleChoice -= 1
            } else {
              this.counterCBT.counterEssay -= 1
            }
            this.questions = this.questions.filter(q => q.key !== soal.key)
            if (soal.id) this.deletedQuestions.push(soal)
            this.selectedSoal = false
            this.selectedKey = ['']
            this.$notification.success({
              message: 'Success',
              description:
                'This Question has been removed',
            })
            this.$emit('questions-change', this.questions)
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Remove',
        })
      }
    },
    startCounter() {
      this.questions = this.listSoal.map(row => {
        return {
          id: row.id,
          tipe_soal: row.tipe_soal,
          soal: row.soal,
          // jawaban: row.tipe_soal === 'Multiple Choice' ? JSON.parse(row.jawaban) : row.jawaban,
          jawaban: row.jawaban,
          kunci_jawaban: row.kunci_jawaban,
          bobot_soal: row.bobot_soal,
          kriteria: row.kriteria,
          indikator: row.indikator,
          standar_kompetensi: row.standar_kompetensi,
        }
      })
      this.counterCBT.counterQuestions = this.questions.length
      this.questions.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
        if (row.tipe_soal === 'Multiple Choice') {
          this.counterCBT.counterMultipleChoice++
        } else if (row.tipe_soal === 'Essay') {
          this.counterCBT.counterEssay++
        } else {
          this.counterCBT.counterEmpty++
        }
      })
    },
  },
  created() {
    this.startCounter()
    // console.log(this.isAttendanceSubmitted)
  },
  computed: {
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      const config = this.$store.state.ckEditor.editorConfig
      return {
        ...config,
        toolbar: {
          items: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            ...config.toolbar.items,
          ],
        },
      }
    },
    valueCorrectAnswer() {
      const kj = this.selectedSoal.kunci_jawaban
      return kj
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>
<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 15px;
}

.wrapper-loading {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 300px 0;
}
</style>
